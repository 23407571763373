const locales = {
  he: {
    //Everywhere
    'Token expired': 'פג תוקף הטוקן',

    //Login page
    'Login': 'התחברות',
    'Email': 'אימייל',
    'Password': 'סיסמה',
    'Forgot Password?': 'שכחת סיסמה?',
    'Individual registration': 'הרשמת משתמש עצמאי',
    'About': 'אודות',
    'Username or password is incorrect': 'שם משתמש או סיסמה לא נכונים',
    'Error': 'שגיאה',
    'Language': 'שפה',
    'This user is under temporary block. The block will be lifted at' : 'משתמש זה נחסם זמנית. החסימה תוסר ב',
    'Wrong password. Attempts left': 'סיסמה שגויה. ניסיונות שנותרו',
    'Log in to continue.': 'התחבר/י על מנת להמשיך.',
    "Your registration is incomplete. Please complete it by setting a password using the registration email you received.": 'ההרשמה שלך טרם הושלמה. אנא השלם את ההרשמה על ידי הגדרת סיסמה באמצעות מייל הרישום שקיבלת.',

    //Login to your files page
    'Login to get to your files': 'התחבר/י כדי לגשת לקבצים שלך',
    
    //Forgot password
    'New password': 'סיסמה חדשה',
    "I'm not a robot": 'אני לא רובוט',
    'Send': 'שליחה',
    'Cancel': 'ביטול',
    'Type your email address to create new password': 'הקלד/י את כתובת האימייל שלך כדי ליצור סיסמה חדשה',
    'A reset password email was sent to the inserted email address' : 'אימייל עם בקשה לאתחול סיסמה נשלח לכתובת',
    'only if it belongs to a known and valid user': 'בתנאי שהיא שייכת למשתמש קיים',
    'Continue': 'המשך',
  
    //Set New Password
    'Set New Password': 'יצירת סיסמה חדשה',
    'Password must contain': 'הסיסמה חייבת להכיל',
    'Minimum 6 characters': 'לפחות 6 תווים',
    'Minimum': 'לפחות',
    'characters': 'תווים',
    'One number': 'סיפרה אחת',
    'Lowercase letter': 'אות קטנה',
    'Uppercase letter': 'אות גדולה',
    'Special symbol': 'תו מיוחד',
    'Insert the previous password if you need the arrived files that are not downloaded yet from the site': 'הזנ/י סיסמה קודמת אם את/ה זקוק/ה לקבצים שטרם הורדו מהאתר',
    'Previous Password': 'סיסמה קודמת',
    "I don't remember my previous password": 'אני לא זוכר/ת את הסיסמה הקודמת שלי',
    'I remember my previous password': 'אני זוכר/ת את הסיסמה הקודמת שלי',
    'Confirm New Password': 'אימות סיסמה חדשה',
    'New Password': 'סיסמה חדשה',
    'Save': 'שמירה',
    'Forgot previous password': 'שכחתי סיסמה קודמת',
    "If you can't remember your previous password, then it will not be possible to access your old files":'אם אינך זוכר/ת את הסיסמה הקודמת שלך, לא תהיה לך אפשרות לגשת לקבצים הישנים שלך',
    "Are you sure you don't remember your password": 'את/ה בטוח/ה שאינך זוכר/ת את הסיסמה שלך',
    'I remember': 'אני זוכר/ת',
    'I Agree to': 'אני מסכימ/ה',
    'terms of use': 'לתנאי השימוש',
    'Previous password is required': 'נדרשת סיסמה קודמת',
    'Minimum characters': 'מינימום ספרות',
    'One number in the password is required': 'נדרשת סיפרה אחת בסיסמה',
    'Lowercase letter in the password is required': 'נדרשת אות קטנה אחת בסיסמה',
    'Uppercase letter in the password is required': 'נדרשת אות גדולה בסיסמה',
    'Special symbol in the password is required': 'נדרש תו מיוחד בסיסמה',
    'Passwords are not equal': 'הסיסמאות לא זהות',
    'Must agree to terms of use': 'עליך להסכים לתנאי השירות',
    'Success': 'הצלחה',
    'Your password was successfully created': 'הסיסמה שלך נוצרה בהצלחה',
    'Your password was successfully updated': 'הסיסמה שלך עודכנה בהצלחה',
    'The request to renew the password has expired. Please send a new request.': 'פג תוקף הבקשה להחלפת סיסמה. אנא שלח בקשה חדשה.',
  
    //Accessibility
    'Accessibility': 'נגישות',
    'Strong colors': 'צבעים חזקים',
    'Regular font': 'גופן רגיל',
    'Animations': 'אנימציות',
    'Font size': 'גודל גופן',
    'Mouse type': 'סוג עכבר',
    'Close': 'סגירה',
  
    //Menu
    // 'Host Admin': 'משתמש ראשי',
    // 'Super Admin': 'מנהל על',
    'Individual': 'משתמש עצמאי',
    // 'Transfer': 'העברה',
    'Profile': 'פרופיל',
    'Management': 'ניהול',
    'Log Out': 'התנתקות',
  
    //Uploading
    'Uploading': 'העלאה',
    'Settings': 'הגדרות',
    'Details': 'פרטים',
    'Route': 'מסלול',
    'Registered Recipients': 'נמענים רשומים',
    'Guest Recipients': 'נמענים אורחים',
    'Guest':"אורחים",
    'Open Guests Phone Book': 'פתח ספר טלפונים של אורחים',
    'Registered' : "רשומים",
    'Select all' : 'בחר הכל', 
    'All selected' : 'הכל נבחר',
    'of': 'מ',
    'selected': 'נבחר',
    'Subject': 'נושא',
    'Message': 'הודעה',
    'Enter password for encrypted files': 'הכנס סיסמה לקבצים מוצפנים',
    'Add Passwords': 'הוסף סיסמאות',
    'Select Files': 'בחירת קבצים',
    'Select Folder': 'בחירת תיקיה',
    'Insert password for protected files': 'הכנס/י סיסמה עבור קבצים מוצפנים',
    'Drag & Drop Files Here': 'גרור ושחרר קבצים כאן',
    'Start': 'התחל',
    'Selected Files': 'קבצים שנבחרו',
    'Total Files': 'מספר קבצים',
    'Total Size': 'גודל הקבצים',
    'Name': 'שם',
    'Size': 'גודל',
    'Last Modified': 'שונה לאחרונה',
    'Policy is required': 'נדרשת מדיניות',
    'At least one registered recipient is required': 'נדרש לפחות נמען רשום אחד',
    'At least one guest recipient is required': 'נדרש לפחות נמען אורח אחד',
    'is required': 'הוא שדה חובה',
    'The value of': 'ערך השדה',
    'is invalid': 'לא תקין',
    'Subject is required': 'נדרש נושא',
    'Request number is required': 'נדרש מספר בקשה',
    'At least one registered or casual recipient is required': 'נדרש לפחות נמען רשום או מזדמן אחד',
    'Subject is invalid': 'נושא לא תקין',
    'Request number is invalid': 'מספר בקשה לא תקין',
    'Files for filtering are missing': 'חסרים קבצים לסינון',
    'Uploading & Filtering Progress': 'התקדמות העלאה וסינון',
    'Duration': 'משך',
    'Uploaded': 'הועלה',
    'Sent': 'נשלח',
    'Results': 'תוצאות',
    'started': 'התחיל',
    'Ended': 'הסתיים',
    'Route': 'מסלול',
    'Subject': 'נושא',
    'Message': 'הודעה',
    'Sent to registered accounts': 'נשלח למשתמשים רשומים',
    'Sent to guest': 'נשלח לאורחים',
    'Close': 'סגירה',
    'Additional Upload': 'העלאה נוספת',
    'are forbidden': 'אסורים לשימוש',
    'Continue': 'המשך',
    'Files Selection': 'בחירת קבצים',
    'Uploading & Filtering': 'העלאה וסינון',
    'Finished': 'סיום',
    'Transference Report': 'ד״וח העברה',
    'Filtering Report': 'דו״ח סינון',
    'Show' : 'הצג',
    'Hide' : 'הסתר',
    'Download Filtering Report' : 'הורד דו״ח סינון',
    'File Details' : 'פרטי קובץ',
    'File type': 'סוג קובץ',
    'Delete' : 'מחק', 
    'Are you sure you want to stop the uploading' : 'האם את/ה בטוח/ה שברצונך לעצור את ההעלאה',
    'Stop' : 'עצור', 
    'Send files to' : 'שלח קבצים אל',
    'According to the security policy, some of the files have not reached their destination' : 'בהתאם למדיניות האבטחה, חלק מהקבצים לא הגיעו ליעדם',
    'Import Emails': 'יבוא כתובות אימייל',
    'Enter Emails': 'הכנסת כתובות דוא"ל', 
    'Add': 'הוספה',
'No recipients defined': 'לא הוגדרו נמענים',

    //Casuals phone book
    'Full Name': 'שם מלא',
    'Add To Send': 'הוספה לשליחה',
    'Link Copied': 'הקישור הועתק',
    'Link copied to clipboard': 'הקישור הועתק ללוח',
    'Copy Link': 'העתק קישור',
    'Copy': 'העתק',
    'Change': 'החלף',
    'Guests Phone Book': 'ספר טלפונים של אורחים',
    'Edit Guest': 'עריכת אורח',
    'Send Email': 'שלח אימייל',
    'Permanent Uploading Link': 'קישור העלאה קבוע',
    'Copy Permanent Uploading Link': 'העתק קישור העלאה קבוע',
    'Change Permanent Uploading Link': 'החלף לינק העלאה קבוע',
    'By changing the link, the current link will be disabled, and a new one will be generated for future use.': 'שים לב, ברגע שתחדש את הקישור, הקישורים שהופקו בעבר יפסיקו להיות פעילים',
    'Change Link': 'החלף קישור',
    'Link changed successfully.': 'קישור הוחלף בהצלחה',
    'Add Guest': 'הוספת אורח',
    'Edit Guest': 'עריכת אורח',
    'Email is required': 'נדרש אימייל',
    'Full name is required': 'נדרש שם מלא',
    'Email is not valid': 'אימייל לא תקין',
    'Mobile is not valid': 'מספר נייד לא תקין',
    'Invalid ID': 'מספר תעודת זהות לא תקין',
    'Mobile is required': 'נדרש נייד',
    'Send a request to': 'שלח בקשה ל ',
    'to send files': 'לשלוח קבצים',
    'Send a request to this guest to send files': 'שלח בקשה לאורח זה לשלוח קבצים',
    'Email Sent Successfully': 'אימייל נשלח בהצלחה',
    'Message (Optional)': 'הודעה (אופציונלי)',
    
    //Downloading
    'Downloading': 'הורדה',
    'Name': 'שם',
    'Status': 'סטטוס',
    'Size': 'גודל',
    'Subject & Message': 'נושא והודעה',
    'Request Number & Message': 'מספר בקשה והודעה',
    'Subject & Sender details': 'נושא ופרטי השולח',
    'Request Number & Sender Details': 'מספר בקשה ופרטי השולח',
    'Date Created': 'תאריך יצירה',
    'Deletion In': 'מחיקה בעוד',
    'Deletion date': 'תאריך מחיקה',
    'More': 'עוד',
    '(No Subject)': '(ללא נושא)',
    '(No Request Number)': '(ללא מספר בקשה)',
    '(No message)': '(ללא הודעה)',
    '(No sender details)': '(ללא פרטי השולח)',
    'Close': 'סגירה',
    'Open search': 'חיפוש',
    'Close Search': 'סגור חיפוש',
    'Directory search': 'חיפוש בתיקיה',
    'Show all files': 'הראה את כל הקבצים',
    'Directory view': 'תצוגת תיקיה',
    'Refresh': 'רענן',
    'All files': 'כל הקבצים',
    'Items': 'פריטים',
    'Size': 'גודל',
    'Session Info': 'פרטי התהליך',
    'Session Information': 'פרטי התהליך',
    'Download Selected': 'הורדת הנבחר',
    'Delete Selected': 'מחיקת הנבחר',
    'View Filtering Report': 'צפייה בדו״ח הסינון',
    'Enter Your Password': 'הכנס/י סיסמה',
    'Due to security reasons, before downloading files please type your login password again':'מסיבות אבטחתיות, לפני הורדת הקבצים אנא הקלד/י שוב את פרטי התחברות שלך',
    'Password': 'סיסמה',
    'Cancel': 'ביטול',
    'Download': 'הורדה',
    'No files were found': 'לא נמצאו קבצים',
    'Folders selected': 'תיקיות שנבחרו', 
    'Files selected': 'קבצים שנבחרו', 
    'Download Report': 'הורד דו״ח',
    'Are you sure you want to delete these files and/or folders': 'האם את/ה בטוח/ה שברצונך למחוק את הקבצים ו/או התיקיות שנבחרו',
    'Never': 'לעולם לא', 
    'Policy': 'מדיניות',
    'Source': 'מקור',
    'Original files size': 'גודל קבצים מקורי',
    'File Information': 'מידע על הקובץ', 
    'History ID': 'מס״ד',
    'Folder Information': 'מידע על התיקייה',
    "You don't have any files or folders in your main folder": 'אין לך קבצים או תיקיות בתיקייה הראשית שלך',
    'No dynamic fields to display': 'אין שדות דינמיים להצגה',
    
    //Alert if there is no routes but yes casuals 
    // 'OOPS...': 'אופס...',
    // 'looks like you are not part of any route but your host have the option for casual users. You can add casual users in your profile section': 'נראה כאילו את/ה לא חלק ממסלול אך לארגון שלך יש אפשרות להשתמש במשתמשים מזדמנים. את/ה יכול/ה להוסיף משתמשים מזדמנים באיזור הפרופיל האישי שלך',
    // 'Go To Profile Section': 'עבור לאיזור הפרופיל האישי',
    
    //Casuals
    'Send Files Securely To' : 'שלח קבצים בצורה מאובטחת ל ',
    ' From ': ' מ ',
    'Download Files Securely From': 'הורד קבצים בצורה מאובטחת מ',
    'by': 'מאת',
    'Recipient': 'נמען',
    'File Name (Size)': 'שם קובץ (גודל)',
    'Upload process has been completed successfully' : 'תהליך ההעלאה הושלם בהצלחה',
    'Recieved files from': 'קבצים שהתקבלו מ',
    '2 Step Verification': 'אימות דו שלבי',
    'A verification code was sent to phone that end with': 'קוד אימות נשלח לטלפון שמסתיים ב',
    'Confirm': 'אימות',
    'Verification Code': 'קוד אימות',
    'You can ask for a new code in': 'את/ה יכול לבקש קוד חדש בעוד',
    'Send New Code': 'שלח קוד חדש',
    'Groups': 'קבוצות',
    'Add Group': 'הוסף קבוצה',
    'Delete field': 'מחיקת שדה',
    'This link is no longer valid.': 'הקישור לא תקף.',
    
    //Profile - Personal
    'Personal': 'אישי',
    'Name': 'שם',
    'Email': 'אימייל',
    'Mobile': 'נייד',
    'Language': 'שפה',
    'Request Reset Password': 'בקשת אתחול סיסמה',
    'By pressing on Continue, you start the procedure to reset your password. Check your email inbox for more details': 'בלחיצה על המשך, יתחיל תהליך איפוס סיסמה. נא לבדוק את תיבת האימייל לקבלת פרטים נוספים.',
    'Continue': 'המשך',
    'Cancel': 'ביטול',
    
    //Profile - My routes  
    'My Routes': 'המסלולים שלי',
    'S/N': 'מס״ד',
    'Routes': 'מסלולים',
    'Senders': 'שולחים',
    'Recipients': 'נמענים',
    'Me': 'אני',
    'Cancel': 'ביטול',
    'Rows per page': 'כמות שורות לכל עמוד',
    'All': 'הכל',
    'of': 'מתוך',
  
    //Profile - My casual accounts
    // 'My Casual Accounts': 'החשבונות המזדמנים שלי',
    // 'My Casual Licenses Usage': 'השימוש שלי ברישיונות מזדמנים',
    // 'Create New Casual User': 'יצירת משתמש מזדמן חדש',
    'Save': 'שמירה',
    'Cancel': 'ביטול',
    'Associate': 'מקושר',
    'Username': 'שם משתמש',
    'Name': 'שם',
    'Mobile': 'נייד',
    'Associated': 'מקושר',
    'Creator': 'יוצר',
    'Direction': 'כיוון',
    'OK' : 'הבנתי' ,
    // 'Send': 'שולח',
    'Send to guest': 'שליחה לאורח',
    'Receive from guest': 'קבלה מהאורח',
    'Send & receive': 'שולח ומקבל',
    'Edit': 'עריכה',
    // 'Hide Archived Casuals': 'הסתר מזדמנים שבארכיון',
    // 'Show Archived Casuals': 'הצג מזדמנים שבארכיון',
    'Activate': 'הפעל',
    // 'You do not have sufficient casual license usage to activate this user as a casual user': 'אין לך מספיק רישיונות מזדמנים כדי להפעיל את המשתמש הזה כמשתמש מזדמן',
    // 'Edit Casual User': 'ערוך משתמש מזדמן',
    'Name': 'שם',
    'Username (Email)': 'שם משתמש (אימייל)',
    'Mobile': 'נייד',
    'Enter phone number' : 'הכנס/י מספר פלאפון',
    'Allow Send': 'אפשר שליחה',
    'Allow send': 'אפשר שליחה',
    'Allow Receive': 'אפשר קבלה',
    'Allow receive': 'אפשר קבלה',
    'Registered user creator': 'יוצר משתמש רשום',
    // 'Archive Casual User': 'שלח משתמש מזדמן לארכיון',
    'Archive' : 'שלח לארכיון',
    'Save': 'שמירה',
    'Cancel': 'ביטול',
    // 'Are you sure you want to activate this casual user':'את/ה בטוח/ה שברצונך להפעיל את המשתמש המזדמן הזה',
    'Activate': 'הפעל',
    // 'Are you sure you want to archive this casual user': 'את/ה בטוח/ה שאתה רוצה לשלוח את המשתמש המזדמן לארכיון',
    // 'This casual user has more than one bounded user. If you archive him, the other users will not be able to transfer files with him. Are you sure you want to continue' : 'למשתמש מזדמן זה יש יותר ממשתמש מקושר אחד. אם תשלח/י אותו לארכיון לא תתאפשר העברת קבצים בינו לבין המשתמשים האחרים. האם את/ה בטוח/ה שברצונך להמשיך',
    'You have unsaved changes. Are you sure you want to leave this page without saving': 'יש לך שינויים שלא נשמרו. את/ה בטוח/ה שברצונך לעזוב את העמוד מבלי לשמור',
    'Leave Without Saving': 'צא מבלי לשמור',
    'None': 'ללא',
    // 'Edit Casual User' : 'ערוך משתמש מזדמן',
    'First name is required': 'נדרש שם פרטי',
    'Last name is required': 'נדרש שם משפחה',
    'Name is required': 'נדרש שם',
    'Email (Username) is required': 'נדרש שם משתמש (אימייל)',
    'Email (Username) must be valid': 'אימייל חייב להיות חוקי',
    'Phone number must be valid': 'מספר טלפון נייד חייב להיות חוקי',
    'Create': 'צור',
    'First Name': 'שם פרטי',
    'Last Name': 'שם משפחה',   
    'Dynamic Fields': 'שדות דינמיים',
    'Guest Related Fields': 'שדות הקשורים לאורחים',
    'Link expiration': 'תוקף הקישור',
    'Minutes': 'דקות',
    'Hours': 'שעות',
    'Days': 'ימים',
    'Send me a confirmation email.': 'שלח לי מייל אישור',
    'Verify guest sender email against impersonation': 'בצע אימות נגד התחזות לאימייל המוזן על ידי השולח',
    'Send Verification Code': 'שלח קוד אימות',
    'Enter Verification Code': 'הכנס קוד אימות',
    "We've sent a verification code to": 'שלחנו קוד אימות ל',
    'Please enter it here': 'בבקשה הכנס אותו כאן',
    "Code": 'קוד',
    "Verify Code": 'אמת קוד',
    'The code is invalid, please try again.': 'הקוד לא תקין, בבקשה נסה שוב.',


    //Profile - My History
    'My History': 'ההיסטוריה שלי',
    'My Guests': 'האורחים שלי',
    'Start Date': 'תאריך התחלה',
    'End Date': 'תאריך סיום',
    // 'Sent': 'נשלח',
    'Received': 'התקבל',
    'Search': 'חיפוש',
    'Start Time': 'זמן התחלה',
    'Subject': 'נושא',
    'Request number': 'מספר בקשה',
    'Request Number': 'מספר בקשה',
    'Sender details': 'פרטי השולח',
    'Sender Details': 'פרטי השולח',
    'Report Summary': 'דוח סיכום',
    'More': 'עוד',
    'Transference Information': 'מידע על העברה',
    'Started': 'התחיל',
    'Size': 'גודל',
    'Route': 'מסלול',
    'Sender': 'שולח',
    'Message': 'הודעה',
    'Received Time': 'זמן קבלה',
    'Received time': 'זמן קבלה',
    'Sent to registered accounts': 'נשלח למשתמשים רשומים',
    'Sent to guests': 'נשלח לאורחים',
    'Transference Results': 'תוצאות העברה',
    'Total': 'סך הכל',
    'Intact': 'מקורי',
    'Modified': 'שונה',
    'Partial': 'חלקי',
    'Rejected': 'נחסם',
    'Close': 'סגור',
    'Show Search': 'הצג חיפוש',
    'Hide Search': 'הסתר חיפוש',
    'Start time': 'זמן התחלה',
    'Summary': 'סיכום',
    'Files of the guest users are deleted successfully': 'קבצי המשתמשים המזדמנים נמחקו בהצלחה',
    'Are you sure you want to delete the files of the guest users?': 'האם את/ה בטוח/ה שברצונך למחוק את הקבצים של המשתמשים המזדמנים?',
    'Delete Guest Files': 'מחיקת קבצי משתמשים מזדמנים',
'Files were already deleted': 'הקבצים כבר נמחקו',

    //Inactivity
    'Inactivity': 'אי פעילות',
    'The system will disconnect in': 'המערכת תתנתק בעוד',
    'seconds': 'שניות',
    'Cancel': 'ביטול',
    'From Guests': 'שליחה על ידי אורחים',
    'To Guests': 'שליחה לאורחים',

    //Guests 
    'Guests': 'אורחים',
    'Delete Guest': 'מחיקת אורח',
    'Are you sure you want to delete this guest?': 'האם את/ה בטוח/ה שברצונך למחוק את האורח הזה?',
    'Fill missing fields': 'מלא שדות חסרים',
    //Casual User Login Verification
    'Email Verification' : 'אימות אימייל',
    'Send Email': 'שלח אימייל',
    'Email verification code sent to your email': 'קוד אימות נשלח למייל שלך',
    'Enter code here':'הכנס קוד כאן',
    'You can ask for a new code in' :'אתה יכול לבקש קוד חדש בעוד', 
    'Back to Login': 'חזרה לדף ההתחברות',
    'Verify': 'אימות',
    'Resend Code':'שלח קוד מחדש ',
    'Code was sent successfully':'קוד נשלח בהצלחה',
    'Verified successfully':'אומת בהצלחה',
    'SFTP Details for Downloading': 'פרטי SFTP להורדה',
    'First Field': 'שדה ראשון',
    'Second Field': 'שדה שני',
    'Third Field': 'שדה שלישי',
    'Add new field': 'הוסף שדה חדש',
    'Label': 'כותרת',
    'The value is part of the target folder hirarchy': 'הערך המוזן יהיה חלק ממסלול תיקיות היעד',
    'Required field': 'שדה חובה',
    'Validation': 'וואלידציה',
    'None': 'ללא', 
        'Email': 'אימייל', 
        'Phone Number': 'מספר נייד', 
        'Israeli ID': 'תעודת זהות ישראלית', 
    'Dynamic Fields were changed successfully.':'השדות הדינמיים נשמרו בהצלחה.',
    'Import Emails':'יבוא כתובות דוא"ל',
    'Link expired': 'הקישור פג תוקף',
    
  }
};
  
  export default locales
  