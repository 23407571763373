<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="fit-content"
  >
    <div class="bg-white">
      <v-card-title class="text-h5 bg-grey font-weight-medium d-flex justify-space-between">

        {{ $t('Permanent Uploading Link') }}
        <!-- <Button
          :clickAction="closeDialog"
        /> -->
        <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon
        style="font-size: 24px; transform: translate(10px, -10px)"
        dense
          v-on="on"
          @click="closeDialog"
          large
          color="black"
          >mdi-close-circle-outline</v-icon
        >
      </template>
      <span>Close</span>
    </v-tooltip>
      </v-card-title>
    </div>
    <div class="bg-white d-flex justify-space-between pa-2">
      <Button :text="$t('Copy')" :clickAction="copyLink" class="mx-4" />
      <Button
        :text="$t('Change')"
        :clickAction="renewLinkClicked"
        btnType="grey"
        class="mx-4"
      />
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PermanentLinkDialog',
  data: () => ({
    dialog: true,
  }),
  components: {
    Button,
  },
  props: {
    closeDialog: Function,
    renewLinkClicked: Function,
    permanentLink: String,
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION']),
    async copyLink() {
      await navigator.clipboard.writeText(this.permanentLink)
      this.SET_NOTIFICATION({
        text: this.$t('Link copied to clipboard'),
        type: 'success',
      })
    },
  },
  created() {
    console.log(this.permanentLink)
  },
}
</script>
