<template>
  <div class="start-end-date-picker-wrapper" :class="[!column && 'd-flex']">
    <!--START DATE-->
    <div class="d-flex flex-column mx-2 my-1 date-field">
      <span class="f-12 label required-field"
        >Start Date ({{ customSearch.dateFormat }})
      </span>
      <date-pick
        v-model="customSearch.startDate"
        pickTime
        :format="'YYYY/MM/DD HH:mm'"
        :inputAttributes="{ readonly: true }"
        :startWeekOnSunday="true"
        :isDateDisabled="isFutureDateOrMoreThanEndDate"
        :selectableYearRange="{ from: 2020, to: 2023 }"
      ></date-pick>
    </div>

    <!--END DATE-->
    <div class="d-flex flex-column mx-2 my-1 date-field">
      <span class="f-12 label required-field"
        >End Date ({{ customSearch.dateFormat }})</span
      >
      <date-pick
        v-model="customSearch.endDate"
        pickTime
        :format="'YYYY-MM-DD HH-mm'"
        :inputAttributes="{ readonly: true }"
        :startWeekOnSunday="true"
        :isDateDisabled="isFutureDateOrLessThanStartDate"
        :selectableYearRange="{ from: 2020, to: 2023 }"
      ></date-pick>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePick from 'vue-date-pick'

export default {
  name: 'StartEndDateSelect',
  props: { customSearch: Object, column: Boolean },

  components: {
    DatePick,
  },

  methods: {
    isFutureDateOrMoreThanEndDate(date) {
      //checks if the input date is in the future or after the end date
      if (!this.customSearch.endDate) return this.isFutureDate(date)
      const endDate = new Date(this.customSearch.endDate)
      const currentDate = new Date()
      return date > currentDate || date > endDate
    },
    isFutureDateOrLessThanStartDate(date) {
      //checks if the input date is in the future or before the start date
      if (!this.customSearch.startDate) return this.isFutureDate(date)
      const startDate = new Date(this.customSearch.startDate)
      const currentDate = new Date()
      return date > currentDate || date < startDate
    },
    isFutureDate(date) {
      const currentDate = new Date()
      return date > currentDate
    },
  },
}
</script>

<style lang="scss">
.start-end-date-picker-wrapper {
  .vdpPositionTop.vdpPositionLeft {
    z-index: 5;
  }
  .vdpComponent input[type='text'] {
    font-size: 16px;
    padding: 2px 6px 0px 3px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    width: 140px;
    cursor: pointer;
  }
  .vdpClearInput {
    display: none;
  }

  .vdpCellContent {
    font-weight: 600;
  }
  .vdpCell.disabled {
    opacity: 0.2 !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
